import { createMuiTheme } from '@material-ui/core/styles';
import './index.css';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  palette: {
    primary: {
      main: '#333333',
      light: '#272727',
      contrastText: '#EBB32A',
    },
    secondary: {
      main: '#D34246',
    },
    background: {
      default: '#F5F7FA',
    },
  },
  typography: {
    fontFamily: ['ubuntu', 'Josefin Sans'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontFamily: 'Josefin Sans',
    },
    h2: {
      fontSize: '1.875rem',
      fontFamily: 'Josefin Sans',
      fontWeight: 600,
      marginBottom: defaultTheme.spacing(2),
    },
    h3: {
      fontSize: '1.5rem',
      fontFamily: 'Josefin Sans',
      fontWeight: 500,
      marginBottom: defaultTheme.spacing(1.5),
    },
    h4: {
      marginBottom: defaultTheme.spacing(1),
      fontSize: '1.25rem',
      fontFamily: 'Josefin Sans',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiPaper: {
      elevation3: {
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.01), 0px 3px 4px 0px rgba(0,0,0,0.01), 0px 1px 8px 0px rgba(0,0,0,0.1)',
      },
    },
    MuiButton: {
      sizeLarge: {
        fontSize: '1.125rem',
        fontWeight: 500,
      },
      contained: {
        color: '#FFF',
        borderRadius: 4,
        backgroundColor: '#EBB32A',
      },
      containedPrimary: {
        backgroundColor: '#D34246',
        borderRadius: 4,
        fontWeight: 300,
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#D34246',
        },
      },
      containedSecondary: {
        borderRadius: 4,
        backgroundColor: '#000000',
        '&:hover': {
          backgroundColor: '#000000',
        },
      },
      outlinedPrimary: {
        borderRadius: 30,
        color: '#333333',
        borderColor: '#333333',
      },
      outlinedSecondary: {
        borderRadius: 30,
        color: '#D34246',
        borderColor: '#D34246',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(1, 2.5),
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '.875rem',
        transform: 'translate(12px, 12px) scale(1)',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#04394E !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&$focused': {
          backgroundColor: '#04394E !important',
        },
      },
      underline: {
        '&::before, &::after': {
          content: 'none',
        },
      },
      input: {
        padding: 12,
      },
      multiline: {
        padding: 12,
      },
    },
    MuiInput: {
      root: {
        padding: defaultTheme.spacing(1, 1, 0.5),
        backgroundColor: '#E5E5E5',
      },
      underline: {
        '&$focused': {
          '&::after': {
            borderColor: '#272727',
          },
        },
      },
    },
  },
});

export const responsiveTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#333333',
      light: '#272727',
      contrastText: '#EBB32A',
    },
    secondary: {
      main: '#D34246',
    },
    background: {
      default: '#F5F7FA',
    },
  },
  typography: {
    fontFamily: ['ubuntu', 'Josefin Sans'].join(','),
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
      // textTransform: 'uppercase',
      marginBottom: defaultTheme.spacing(2),
    },
    h3: {
      fontSize: '1.25rem',
      // textTransform: 'uppercase',
      fontWeight: 500,
      marginBottom: defaultTheme.spacing(1.5),
    },
    h4: {
      marginBottom: defaultTheme.spacing(1),
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiPaper: {
      elevation3: {
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.01), 0px 3px 4px 0px rgba(0,0,0,0.01), 0px 1px 8px 0px rgba(0,0,0,0.1)',
      },
    },
    MuiButton: {
      sizeLarge: {
        fontSize: '1rem',
        fontWeight: 500,
      },
      contained: {
        color: '#FFF',
        borderRadius: 0,
        backgroundColor: '#EBB32A',
      },
      containedPrimary: {
        backgroundColor: '#D34246',
        fontWeight: 300,
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#D34246',
        },
      },
      containedSecondary: {
        backgroundColor: '#000000',
        '&:hover': {
          backgroundColor: '#000000',
        },
      },
      outlinedPrimary: {
        color: '#333333',
        borderColor: '#333333',
      },
      outlinedSecondary: {
        color: '#D34246',
        borderColor: '#D34246',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(1, 2.5),
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '.875rem',
        transform: 'translate(12px, 12px) scale(1)',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#04394E !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&$focused': {
          backgroundColor: '#04394E !important',
        },
      },
      underline: {
        '&::before, &::after': {
          content: 'none',
        },
      },
      input: {
        padding: 12,
      },
      multiline: {
        padding: 12,
      },
    },
    MuiInput: {
      root: {
        padding: defaultTheme.spacing(1, 1, 0.5),
        backgroundColor: '#E5E5E5',
      },
      underline: {
        '&$focused': {
          '&::after': {
            borderColor: '#272727',
          },
        },
      },
    },
  },
});

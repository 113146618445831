import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const TransitionLink = (props: any) => {
  const { children, settings } = props;
  const defaultSettings = {
    cover: true,
    direction: 'right',
    bg: '#272727',
  };

  if (settings) Object.assign(defaultSettings, settings);

  return (
    <AniLink {...defaultSettings} {...props}>
      {children}
    </AniLink>
  );
};

export { TransitionLink };
